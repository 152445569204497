<template>
  <div id="policyTsft" >
     <div class="about" id="warp">
      <div class="company-details">
        <div class="container">
          <div class="privacy_policy">
            <p>1.欢迎您使用富通源 APP软件及服务！</p>
            <p>2.为使用富通源 APP软件（以下简称“本软件”）及服务，您应当阅读并遵守《富通源APP软件许可协议》（以下简称“本协议”），以及《富通源服务协议》。请您务必审慎阅读、充分理解各条款内容，特别是免除或者限制责任的条款，以及开通或使用某项服务的单独协议，并选择接受或不接受。限制、免责条款可能以加粗形式提示您注意。</p>
            <p>3.除非您已阅读并接受本协议所有条款，否则您无权下载、安装或使用本软件及相关服务。您的下载、安装、登录等使用行为即视为您已阅读并同意上述协议的约束。
            </p>
            <p>4.如果您未满18周岁，请在法定监护人的陪同下阅读本协议及其他上述协议，并特别注意未成年人使用条款。</p>
            <p>
              一、协议的范围
            <p>
              1.1 协议适用主体范围
            </p>
            <p>
              本协议被视为《富通源服务协议》的补充协议，是其不可分割的组成部分，与其构成统一整体。本协议与《富通源服务协议》存在冲突的，以本协议为准。
            </p>
            <p>
             本协议内容同时包括富通源可能不断发布的关于本服务的相关协议、业务规则等内容。上述内容一经正式发布，即为本协议不可分割的组成部分，您同样应当遵守并受本协议约束。
            </p>
            <p>
             二、关于本服务
            </p>
            <p>
              2.1 本服务的内容<br/>
              本服务内容是指富通源向您提供的安装在包括但不限于移动电话、平板电脑等各种手持移动终端设备上，用于获取文字、图片、视频等信息、设置信息推送提醒、查看关注信息等功能的软件许可及服务（以下简称“本服务”）。<br/>

              2.2 本服务的形式<br/>

              本服务中富通源APP客户端软件提供包括但不限于Ios及Android等多个版本，您必须选择与所安装手机相匹配的软件版本。<br/>

              2.3 本服务许可的范围<br/>

                2.3.1 富通源给予您一项个人的、不可转让及非排他性的许可，以使用本软件。您可以为非商业目的在单一台终端设备上安装、使用、显示、运行本软件。<br/>

              2.3.2 您可以用计算机可读形式制作本软件的一个副本，仅用作备份。备份副本必须包含原软件中含有的所有知识产权信息。<br/>

               2.3.3 本条及本协议其他条款未明示授权的其他一切权利由富通源保留，您在行使这些权利时须另外取得富通源的书面许可。富通源如果未行使前述任何权利，并不构成对该权利的放弃。
            </p>
            <p>
              三、软件的获取
            </p>
            <p>
            1.3.1 您可以直接从富通源的网站上获取本软件，也可以从得到富通源授权的第三方获取。<br/>

            2.3.2 如果您从未经富通源授权的第三方获取本软件或与本软件名称相同的安装程序，富通源无法保证该软件能够正常使用，并对因此给您造成的损失不予负责。<br/>

            3.13.3 本协议于首页更新时间的10日后生效。
            </p>
            <p>
            四、软件的安装与卸载
            </p>
            <p>1.4.1 富通源可能为不同的终端设备开发了不同的软件版本，您应当根据实际情况选择下载合适的版本进行安装。<br>
            2.4.2 下载安装程序后，您需要按照该程序提示的步骤正确安装。<br>
            3.4.3 为提供更加优质、安全的服务，在本软件安装时富通源可能推荐您安装其他软件，您可以选择安装或不安装。<br>
            4.4.4 如果您不再需要使用本软件或者需要安装新版软件，可以自行卸载。如果您愿意帮助富通源改进产品服务，请告知卸载的原因。
            </p>
            <p>
           五、软件的更新
            </p>
            <p>
            5.1 为了改善用户体验、完善服务内容，富通源将不断努力开发新的服务，并为您不时提供软件更新（这些更新可能会采取软件替换、修改、功能强化、版本升级等形式）。<br>

            5.2 为了保证本软件及服务的安全性和功能的一致性，富通源有权不向您特别通知而对软件进行更新，或者对软件的部分功能效果进行改变或限制。<br>

            5.3 本软件新版本发布后，旧版本的软件可能无法使用。富通源不保证旧版本软件继续可用及相应的客户服务，请您随时核对并下载最新版本。
            </p>
            <p>
           六、用户个人信息保护
            </p>
            <p>6.1 保护用户个人信息是富通源 的一项基本原则，富通源将会采取合理的措施保护用户的个人信息。除法律法规规定的情形外，未经用户许可富通源不会向第三方公开、透露用户个人信息。为了向用户提供相关服务功能或改善技术和服务，您在注册帐号或使用本服务的过程中，可能需要提供一些必要的信息。富通源对相关信息采用国际化标准的加密存储与传输方式，保障用户个人信息的安全。<br>

            6.2 您在注册帐号或使用本服务的过程中，可能需要提供一些必要的信息，例如：为向您提供帐号注册服务，需要您填写手机号码等信息；地区新闻服务需要您同意使用您所在的地理位置信息。若国家法律法规或政策有特殊规定的，您需要提供真实的身份信息。若您提供的信息不完整，则无法使用本服务或在使用过程中受到限制。<br>

            6.3 富通源将运用各种安全技术和程序建立完善的管理制度来保护您的个人信息，以免遭受未经授权的访问、使用或披露。<br>

            6.4 未经您的同意，富通源不会向富通源以外的任何公司、组织和个人披露您的个人信息，但法律法规另有规定的除外。<br>

            6.5 富通源非常重视对未成年人个人信息的保护。若您是18周岁以下的未成年人，在使用富通源的服务前，应事先取得您家长或法定监护人的书面同意。</p>
            <p>七、主权利义务条款</p>
            <p>7.1 帐号使用规范<br>

            7.1.1 您在使用本软件及服务中手机防盗、安全登录等部分功能时可能需要登录手机帐号。<br>

            7.1.2 富通源特别提醒您应妥善保管您的帐号和密码。当您使用完毕后，应安全退出。因您保管不善可能导致遭受盗号或密码失窃等，责任由您自行承担。<br>

            7.2 用户注意事项<br>

            7.2.1 您理解并同意：为了向您提供有效的服务，您在此许可富通源利用您移动通讯终端设备的处理器和宽带等资源。本软件使用过程中可能产生数据流量的费用，您需自行向运营商了解相关资费信息。<br>

            7.2.2 您理解并同意富通源将会尽其商业上的合理努力以保护您移动通讯终端与本软件及服务相关的资源及通讯的隐私性和完整性，但是，富通源并不能就此提供完全保证。<br>

            7.2.3 您在使用本软件及服务时，须自行承担如下来自富通源不可掌控的风险内容，包括但不限于：7.2.3.1 由于您选择与所安装手机型号不相匹配的软件版本所导致的任何软件问题、手机问题或损害；<br>

            7.2.3.2 您的手机隐私信息在使用本软件及服务时可能产生的信息安全风险；<br>

            7.2.3.3 您在使用本软件访问第三方网站或信息系统时遇到内容质量及安全问题等；<br>

            7.2.3.4 由于无线网络信号不稳定、无线网络宽带问题等原因，所引起的富通源APP登录失败、资料同步不完整、页面打开速度慢等风险。</p>
            
<p> 八、用户行为规范</p>
<p>
  1.8.1 信息内容规范<br>
8.1.1 您可使用本软件及服务发表属于您原创或您有权发表的观点看法、数据、文字、信息、用户名、图片、照片、个人信息、音频、视频文件、链接等信息内容。您必须保证，您拥有您所上传信息内容的知识产权或已获得合法授权，您使用本软件及服务的任何行为未侵犯任何第三方之合法权益。富通源并无义务对您发表的上述内容进行审核，如因您发表的信息被第三方认定侵权，相关责任由您自行承担，因此给富通源及其关联公司造成损失的，您应当予以全额赔偿，同时富通源有权根据相关证据结合《侵权责任法》、《信息网络传播权保护条例》等法律法规对侵权信息进行相应处理。8.1.2 您在使用本服务时需遵守法律法规、社会主义制度、国家利益、公民合法权益、社会公共秩序、道德风尚及信息真实性等“七条底线”要求。<br>

8.1.2 您在使用本服务时需遵守法律法规、社会主义制度、国家利益、公民合法权益、社会公共秩序、道德风尚及信息真实性等“七条底线”要求。<br>

8.1.3 您在使用本服务时不得利用本服务从事以下行为，包括但不限于：<br>
8.1.3.1 制作、复制、发布、传播、储存违反国家法律法规的内容：<br>

（1）反对宪法所确定的基本原则的；<br>

（2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；<br>

（3）损害国家荣誉和利益的；<br>

（4）煽动民族仇恨、民族歧视，破坏民族团结的；<br>

（5）破坏国家宗教政策，宣扬邪教和封建迷信的；<br>

（6）散布谣言，扰乱社会秩序，破坏社会稳定的；<br>

（7）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；<br>

（8）侮辱或者诽谤他人，侵害他人合法权益的；<br>

（9）煽动非法集会、结社、游行、示威、聚众扰乱社会秩序；<br>

（10）以非法民间组织名义活动的；<br>

（11）含有法律、行政法规禁止的其他内容的。<br>

8.1.3.2 发布、传送、传播、储存侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的内容；<br>

8.1.3.3 虚构事实、隐瞒真相以误导、欺骗他人；<br>

8.1.3.4 发表、传送、传播广告信息及垃圾信息；<br>

8.1.3.5 从事其他违反法律法规、政策及公序良俗、社会公德等的行为。<br>

8.2 软件使用规范除非法律允许或富通源书面许可，您使用本软件过程中不得从事下列行为：<br>

8.2.1 删除本软件及其副本上关于知识产权的信息；<br>

8.2.2 对本软件进行反向工程、反向汇编、反向编译，或者以其他方式尝试发现本软件的源代码；<br>

8.2.3 对富通源拥有知识产权的内容进行使用、出租、出借、复制、修改、链接、转载、汇编、发表、出版、建立镜像站点等；<br>

8.2.4 对本软件或者本软件运行过程中释放到任何终端内存中的数据、软件运行过程中客户端与服务器端的交互数据，以及本软件运行所必需的系统数据，进行复制、修改、增加、删除、挂接运行或创作任何衍生作品，形式包括但不限于使用插件、外挂或非富通源经授权的第三方工具/服务接入本软件和相关系统；<br>

8.2.5 通过修改或伪造软件运行中的指令、数据，增加、删减、变动软件的功能或运行效果，或者将用于上述用途的软件、方法进行运营或向公众传播，无论这些行为是否为商业目的；<br>

8.2.6 通过非富通源开发、授权的第三方软件、插件、外挂、系统，登录或使用富通源软件及服务，或制作、发布、传播上述工具；<br>

8.2.7 其他未经富通源明示授权的行为。<br>

8.3 对自己行为负责<br>

您充分了解并同意，您必须为自己注册帐号下的一切行为负责，包括您所发表的任何内容以及由此产生的任何后果。您应对本服务中的内容自行加以判断，并承担因使用内容而引起的所有风险，包括因对内容的正确性、完整性或实用性的依赖而产生的风险。富通源无义务且不会对因前述风险而导致的任何损失或损害承担责任，如富通源先行承担任何赔偿责任的，有权向您全额追偿。<br>

8.4 违约处理<br>

8.4.1 如果富通源发现或收到他人举报或投诉用户违反本协议约定的，富通源有权不经通知随时对相关内容进行删除，并视行为情节对违规帐号处以包括但不限于警告、限制或禁止使用全部或部分功能、帐号封禁直至注销的处罚，并公告处理结果。<br>

8.4.2 您理解并同意，富通源有权依合理判断对违反有关法律法规或本协议规定的行为进行处罚，对违法违规的任何人士采取适当的法律行动，并依据法律法规保存有关信息向有关部门报告等，用户应独自承担由此而产生的一切法律责任。<br>

8.4.3 您理解并同意，因您违反本协议或相关服务条款的规定，导致或产生第三方主张的任何索赔、要求或损失，您应当独立承担责任；富通源及其关联公司因此遭受损失的，您也应当一并赔偿。
</p>
<p>
九、知识产权声明
</p>
<p>
9.1 富通源是本软件的知识产权权利人。本软件的一切著作权、商标权、专利权、商业秘密等知识产权，以及与本软件相关的所有信息内容（包括但不限于文字、图片、音频、视频、图表、界面设计、版面框架、有关数据或电子文档等）均受中华人民共和国法律法规和相应的国际条约保护，富通源享有上述知识产权。
<br>
9.2 未经富通源或相关权利人书面同意，您不得为任何商业或非商业目的自行或许可任何第三方实施、利用、转让上述知识产权。
</p>
<p>
十、终端安全责任
</p>
<p>
  10.1 您理解并同意，本软件同大多数互联网软件一样，可能会受多种因素影响，包括但不限于用户原因、网络服务质量、社会环境等；也可能会受各种安全问题的侵扰，包括但不限于他人非法利用用户资料，进行现实中的骚扰；用户下载安装的其他软件或访问的其他网站中可能含有病毒、木马程序或其他恶意程序，威胁到您的终端设备信息和数据安全，继而影响本软件的正常使用等。因此，您应加强信息安全及个人信息的保护意识，注意密码保护，以免遭受损失。
<br>
10.2 您不得制作、发布、使用、传播用于窃取手机号码及他人个人信息、财产的恶意程序。
<br>
10.3 维护软件安全与正常使用是富通源 和您的共同责任，富通源将按照行业标准合理审慎地采取必要技术措施保护您的终端信息和数据安全，但是您承认和同意富通源并不能就此提供完全保证。
</p>

<p>
  十一、第三方软件或技术
</p>
<p>
 11.1 本软件可能会使用第三方软件或技术（包括本软件可能使用的开源代码和公共领域代码等，下同），这种使用已经获得合法授权，本软件应遵循相应的开源协议，对应的开源协议请参见本协议附件。<br>

11.2 本软件如果使用了第三方的软件或技术，富通源将按照相关法规或约定，对相关的协议或其他文件，可能通过本协议附件、在本软件安装包特定文件夹中打包等形式进行展示，它们可能会以“软件使用许可协议”、“授权协议”、“开源代码许可证”或其他形式来表达。前述通过各种形式展现的相关协议或其他文件，均是本协议不可分割的组成部分，与本协议具有同等的法律效力，您应当遵守这些要求。如果您没有遵守这些要求，该第三方或者国家机关可能会对您提起诉讼、罚款或采取其他制裁措施，并要求富通源给予协助，您应当自行承担法律责任。
<br>
11.3 如因本软件使用的第三方软件或技术引发的任何纠纷，应由该第三方负责解决，富通源 不承担任何责任。富通源不对第三方软件或技术提供客服支持，若您需要获取支持，请与第三方联系。
</p>
<p>
 十二、其他
</p>
<p>
 12.1 您使用本软件即视为您已阅读并同意受本协议的约束。富通源有权在必要时修改本协议条款。您可以在本软件的最新版本中查阅相关协议条款。本协议条款变更后，如果您继续使用本软件，即视为您已接受修改后的协议。如果您不接受修改后的协议，应当停止使用本软件。
<br>
12.2 本协议的成立、生效、履行、解释及纠纷解决，适用中华人民共和国大陆地区法律（不包括冲突法）。
<br>
12.3 若您和富通源之间发生任何纠纷或争议，首先应友好协商解决；协商不成的，您同意将纠纷或争议提交本协议签订地有管辖权的人民法院管辖。
<br>
12.4 本协议所有条款的标题仅为阅读方便，本身并无实际涵义，不能作为本协议涵义解释的依据。
<br>
12.6 本协议条款无论因何种原因部分无效或不可执行，其余条款仍有效，对双方具有约束力。
</p>
    </div>
        </div>
      </div>
     </div>

  </div>
</template>

<script>
import eventBusMitter from '../../../utils/eventbus';
export default {
  name: 'policyTsft2',
  data(){
    return{
      spJY:false,
      companyName:'成都依荣布科技有限公司',
      version:'V3.1.1',
      appName:'红狮',
      channel:'',
      spContent:'客户可不时查询及更改由交易商所保存的有关个人资料；客户如需删除个人信息、注销账户或撤回已同意的授权，可联系客服核实处理，前述指示将在10个工作日内完成。客户的账号注销完成后，公司将立即销毁用户的个人信息。'
    }
  },
  mounted(){
    document.title = "客户协议"
  },
  created(){
    // 接收eventBus传来的值
    // eventBusMitter.on('eventBus',(res)=>{
    //   this.control = res.key;
    // })
    this.channel = this.$route.query.channel
    this.appName = this.$route.query.appName?this.$route.query.appName :'红狮'
     this.version =  this.$route.query.version? this.$route.query.version :'V3.1.1'
    switch(this.channel){
      case '9901':
        this.companyName='成都莎亚馨伟科技有限公司 '
        break
      case '9920':
        this.companyName='成都莎亚馨伟科技有限公司'
        break
      case '9923':
        this.companyName='成都莎亚馨伟科技有限公司'
        break
      case '9902':
         this.companyName='成都依荣布科技有限公司'
        break
      case '9903':
         this.companyName='香港红狮集团有限公司'
        break
      case '9907':
         this.companyName='香港红狮集团有限公司'
        break
       case '9917':
         this.companyName='香港红狮集团有限公司'
        break
      case '9918':
         this.companyName='香港红狮集团有限公司'
        break
      case '9919':
         this.companyName='香港红狮集团有限公司'
        break
      case '9910':
         this.companyName='香港红狮集团有限公司'
        break
      case '9911':
         this.companyName='香港红狮集团有限公司'
        break
      case '9912':
         this.companyName='香港红狮集团有限公司'
        break
      case '9908':
         this.companyName='香港红狮集团有限公司'
        break
      case '9909':
         this.companyName='香港红狮集团有限公司'
        break
      case '9922':
         this.companyName='香港红狮集团有限公司'
        break
      case '9915':
         this.companyName='香港红狮集团有限公司'
        break
      case '9906':
         this.companyName='香港红狮集团有限公司'
        break
      case '9999':
         this.companyName='香港红狮集团有限公司'
        break
      case '9801':
      case '9802':
        this.companyName='上海前青信息科技有限公司'
        break
      case '9820':
        this.companyName='上海前青信息科技有限公司'
        break
      case '9823':
        this.companyName='上海前青信息科技有限公司'
        break
      case '9925':
         this.companyName='上海前青信息科技有限公司'
        break
      case 'HFTZ7006':
         this.companyName='山东齐盛期货有限公司'
      break
      case 'FTTZ006':
         this.companyName='渤海汇金证券资产管理有限公司'
      break
      case '1003':
         this.spContent ='客户如需删除个人信息、注销账户，可在【我的】-【APP设置】-【注销账户】进入后联系客服核实处理，公司将会在10个工作日内完成客户的账户注销，并立即销毁用户的个人信息。'
          this.spJY = true
        break
    }
    // this.companyName = this.$route.query.companyName
    //  this.version = this.$route.query.version
    //  this.appName = this.$route.query.appName
     eventBusMitter.emit('eventBus', {key:true})
  },
}
</script>

<style lang="less" scope>

      @charset "utf-8";
      a,
      button {
        -webkit-appearance: none;
        border-radius: 0;
        border: none;
        outline: 0;
      }

      body * {
        font-family: "microsoft yahei", Helvetica;
      }

      body {
        -webkit-text-size-adjust: 100%;
        background: #e8e8e8;
        margin: 0;
        padding: 0;
        -webkit-user-select: none;
        color: #515151;
        font-size: unset;
      }

      div,
      h1,
      p,
      h2,
      h3,
      h4,
      h5,
      h6,
      ul,
      li,
      span,
      input,
      p,
      a,
      table,
      th,
      tr td {
        margin: 0;
        padding: 0;
      }

      html,
      body {
        width: 100%;
        height: 100%;
        overflow: hidden;
      }


      .contact_item_cont ::-webkit-input-placeholder {
        color: #ccc;
      }

      .contact_item_cont ::-moz-placeholder {
        color: #ccc;
      }

      .contact_item_cont :-ms-input-placeholder {
        color: #ccc;
      }

      .contact_item_cont input:-moz-placeholder {
        color: #ccc;
      }

      .container {
        padding: 0 4%;
      }

      .fl {
        float: left;
      }

      .fr {
        float: right;
      }

      .fix:after {
        content: "";
        display: block;
        clear: both;
      }

      .fix {
        zoom: 1;
      }

      .hide {
        display: none !important;
      }

      .text-left {
        text-align: left;
      }

      .text-right {
        text-align: right;
      }


      /**主体内容**/
      #warp {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
      }

      #warp.navpanel-open {
        width: 100%;
        position: fixed;
        overflow: hidden;
      }

.company-details {
  font-size: 12px;
  color: #515151;
  padding: 1rem 0;
  line-height: 24px;
  font-size: 12px;
}

.company-details p {
  line-height: 20px;
  margin-top: 0.2rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}
.privacy_policy p span {
  /* display: inline-block;
    vertical-align: top; */
}
#policyTsft{
  font-size: 12px!important;
}
</style>
